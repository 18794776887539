.page_404 {
	padding: 40px 0;
	width: 80%;
	top: 20%;
	position: absolute;
	text-align: center;
}

.four_zero_four_bg {
	height: 150px;
	background-position: center;
}

.four_zero_four_bg h1 {
	font-size: 80px;
}

.four_zero_four_bg h3 {
	font-size: 80px;
}

.link_404 {
	color: #000 !important;
	padding: 10px 20px;
	background: lightblue;
	margin: 20px 0;
	display: inline-block;
}
.contant_box_404 {
	margin-top: -50px;
	a {
		text-decoration: none;
	}
}
